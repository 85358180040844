var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{attrs:{"grow":"","show-arrows":"","background-color":"grey","color":"white"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.disciplines),function(d){return _c('v-tab',{key:d._id,attrs:{"href":("#tab-" + (d._id))}},[_vm._v(" "+_vm._s(d.order)+". "+_vm._s(d.name)+" ")])}),_vm._l((_vm.disciplines),function(d){return _c('v-tab-item',{key:d._id,attrs:{"value":("tab-" + (d._id))}},[_c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v("Kampfgericht ("+_vm._s(d.name)+")")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getJudge('dh', d._id),"label":("Kampfrichter D1 (Heim, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.save('dh', value, id);
}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getJudge('dg', d._id),"label":("Kampfrichter D2 (Gast, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.save('dg', value, id);
}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getJudge('e1', d._id),"label":("Kampfrichter E1 (Oberkampfrichter, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.save('e1', value, id);
}}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.$store.getters.isMaster)?_c('base-edit-dialog-vereinsuche',{attrs:{"value":_vm.getClub('e1', d._id),"label":("Verein Kampfrichter E1 (Oberkampfrichter, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.saveclub('e1', value, id);
}}}):_c('v-text-field',{attrs:{"disabled":"","value":_vm.getClub('e1', d._id),"label":("Verein Kampfrichter E1 (Oberkampfrichter, " + (d.name) + ")")}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getJudge('e2', d._id),"label":("Kampfrichter E2 (neutral, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.save('e2', value, id);
}}})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.$store.getters.isMaster)?_c('base-edit-dialog-vereinsuche',{attrs:{"value":_vm.getClub('e2', d._id),"label":("Verein Kampfrichter E2 (neutral, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.saveclub('e2', value, id);
}}}):_c('v-text-field',{attrs:{"disabled":"","value":_vm.getClub('e2', d._id),"label":("Verein Kampfrichter E2 (neutral, " + (d.name) + ")")}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getJudge('e3', d._id),"label":("Kampfrichter E3 (Heim, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.save('e3', value, id);
}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm.getJudge('e4', d._id),"label":("Kampfrichter E4 (Gast, " + (d.name) + ")"),"id":d._id,"clearable":""},on:{"input":function (ref) {
	var value = ref.value;
	var id = ref.id;

	return _vm.save('e4', value, id);
}}})],1)],1),_c('h2',[_vm._v("Hinweise:")]),_c('ul',[_c('li',[_vm._v("Wichtig für den Wettkampf ist, dass die eingegebene Anzahl der Abzugs-Kampfrichter stimmt!")]),_c('li',[_vm._v("Es können aktuell noch keine neuen Personen im System angelegt werden.")]),_c('li',[_vm._v("Soll eine Person als Kampfrichter genutzt werden, die noch nicht im System hinterlegt ist, so eine andere Person eingeben und eine E-Mail an "),_c('a',{attrs:{"href":"mailto:ergebnisdienst@stb.de"}},[_vm._v("ergebnisdienst@stb.de")]),_vm._v(" schreiben!")])])],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }